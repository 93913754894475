import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from '@remix-run/react'
import React, { useEffect, useState } from 'react'
import { useAuth, useUser } from '../../../lib/wundergraph'
import LogoLarge from '../../assets/platform_logos/Logo_design_whitebg_750px.webp'

export default function Hero({ signupUrl }) {
  const { login, logout } = useAuth()
  // const { data: user, isLoading: userLoading } = useUser()

  function handleLogin() {
    login('keycloak')
  }

  return (
    <div className="sm:max-w-1xl relative isolate mx-auto flex flex-col place-items-center overflow-hidden px-6 pt-6 text-center sm:rounded-3xl lg:max-w-6xl">
      <div className="relative h-0 max-w-sm px-2 pt-8 md:max-w-sm lg:max-w-md">
        <img src={LogoLarge} className="mx-auto flex" />
      </div>
      <div className="pt-28 md:pt-36 lg:pt-36">
        <p className="pb-2 text-lg leading-8 text-gray-600 md:text-xl lg:text-xl">
          Shop online and conveniently pick up wholesome grocery donations from participating stores!
        </p>
      </div>

      <div className="flex max-h-12 min-h-12 items-center justify-center gap-x-6">
        <button
          onClick={() => (window.location.href = signupUrl)}
          className="mt-3 rounded-md bg-warmTerracota-700 px-3.5 py-1.5 text-sm text-white shadow-sm hover:bg-warmTerracota-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          Sign Up
        </button>

        {/* <Link
            to="/learnmore"
            className="text-center text-sm font-semibold text-warmTerracota-700"
          >
            Learn more <span aria-hidden="true">→</span>
          </Link> */}
      </div>
    </div>
  )
}
